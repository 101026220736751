import Link from "next/link";
import styles from "./index.module.scss";
import { FC } from "react";
import { ICardMini } from "@/shared/types/types";
import SwiperCollection from "@/shared/components/SwiperCollection";
import clsx from "clsx";
interface ISwiperCardList {
  isVisibleTitle: boolean;
  hasLink: boolean;
  title: string;
  link?: string;
  items: Array<ICardMini>;
  onClick?: () => void;
}
const SwiperCardList: FC<ISwiperCardList> = props => {
  const {
    isVisibleTitle,
    hasLink,
    items,
    title,
    link = "",
    onClick
  } = props;
  return <section className={styles.section} data-sentry-component="SwiperCardList" data-sentry-source-file="index.tsx">
      <h2 className={clsx(styles.title, !isVisibleTitle && styles.title)}>
        {hasLink ? <>
            <Link prefetch={false} href={link}>
              {title}
            </Link>
            <Link prefetch={false} className={styles.titleLink} href={link}>
              смотреть
            </Link>
          </> : title}
      </h2>
      <SwiperCollection items={items} onClick={onClick} data-sentry-element="SwiperCollection" data-sentry-source-file="index.tsx" />
    </section>;
};
export default SwiperCardList;