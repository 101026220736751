import { mindboxGetEventRecommendation } from "@/utils/api/mindbox";
import { useEffect, useState } from "react";
import { getItemsList } from "@/utils/api/catalog";
import { usePathname } from "next/navigation";
import Loading from "@/shared/components/Loader";
import SwiperCardList from "@/shared/components/SwiperCardList";
const PersonalRecommended = () => {
  const pathname = usePathname();
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const handleRecommendedClick = () => {
    ym(42989679, "reachGoal", "goToProductFromRecommendations");
  };
  useEffect(() => {
    mindboxGetEventRecommendation().then(res => {
      const ids = res.data.recommendations.map((item: any) => !pathname.includes(item.ids.website) ? item.ids.website : 0).join(",");
      getItemsList(ids, true, false, false, true).then(res => {
        if (res.items.items.length === 0) return setIsVisible(false);
        setItems(res.items.items);
        setIsLoading(false);
      }).catch(err => {
        setIsVisible(false);
      });
    }).catch(err => {
      setIsVisible(false);
    });
  }, []);
  if (!isVisible) return null;
  return <section data-sentry-component="PersonalRecommended" data-sentry-source-file="index.tsx">
      {isLoading ? <Loading type={"preLoader"} /> : <SwiperCardList isVisibleTitle hasLink={false} title={"Может быть интересно"} items={items} onClick={handleRecommendedClick} />}
    </section>;
};
export default PersonalRecommended;