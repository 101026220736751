import styles from "./index.module.scss";
import classNames from "classnames";
interface ILoading {
  type?: "base" | "preLoader" | "mini";
  className?: string;
}
const Loading = (props: ILoading) => {
  const {
    type = "base",
    className
  } = props;
  const preloaderClassNames = classNames({
    [className!]: className,
    [styles.preloaderWrap]: true
  });
  const loaderClassNames = classNames({
    [styles.loader]: true,
    [styles.mini]: true
  });
  if (type === "preLoader") {
    return <div className={preloaderClassNames}>
        <div className={styles.loader}></div>
      </div>;
  }
  if (type === "mini") {
    return <div className={preloaderClassNames}>
        <div className={loaderClassNames}></div>
      </div>;
  }
  return <div className={styles.wrap} data-sentry-component="Loading" data-sentry-source-file="index.tsx">
      <div className={styles.loader}></div>
    </div>;
};
export default Loading;