import { request } from "../functions/request";
export async function getCatalog() {
  return request(`/menu/get_list_tree.php`);
}
export async function getSeoCatalog(url?: string) {
  return url ? request(`/seo/?url=catalog/&section=${url}`) : request(`/seo/?url=catalog/`);
}
export async function getNestedCatalog(section: string) {
  return request(`/catalog/get_items_list.php?section=${section}&price_check=true`);
}
export async function getFilters(section: string) {
  return request(`/catalog/get_filter_section.php?section=${section}`);
}
export async function getComissionFilters() {
  return request(`/catalog/get_filter_comission.php`);
}
export async function getInfoCatalog(section: string) {
  return request(`/catalog/get_section_info.php?section=${section}`);
}
export async function getItemsList(codes: string, type?: boolean, noCheckPrices?: boolean, stock?: boolean, pictureCheck?: boolean) {
  return request(`/catalog/get_items_list.php?${codes.split(",").map(code => `${type ? `id` : `code`}[]=${code}`).join("&")}${noCheckPrices ? `` : `&price_check=true`}${stock ? `&stock=not_now` : ``}${pictureCheck ? `&picture_check=true` : ``}`);
}
export async function getTagsCloud(section: string) {
  return request(`/cloud_tag/get_bottom.php?section_code=${section}`);
}
export async function getCatalogBrands(brands: any) {
  return request(`/brand/get_list.php?${brands.map((brand: any) => `code[]=${brand.code}`).join("&")}`);
}
export async function getSubsections(code: any) {
  return request(`/menu/get_subsection.php?section=${code}`);
}
export async function getAllSubsections(section: any) {
  return request(`/menu/get_subsection_tree.php?section=${section}`);
}